
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.app-container {
  background-color: black;
  font-family: var(--font-family);
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 0;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1080px) {
  .app-container::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar {
  background-color: #111111;
  width: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
}

@font-face {
  font-family: "Wov";   /*Can be any text*/
  src: local("WOV"),
    url("./fonts/wov1.otf") format("truetype");
}


@font-face {
  font-family: "Neue";   /*Can be any text*/
  src: local("Neue"),
    url("./fonts/Neue.otf") format("truetype");
}

@font-face {
  font-family: "NeueUltra";   /*Can be any text*/
  src: local("NeueUltra"),
    url("./fonts/NeueUltra.otf") format("truetype");
}

@font-face {
  font-family: "NeueLight";   /*Can be any text*/
  src: local("NeueLight"),
    url("./fonts/NeueLight.otf") format("truetype");
}

@font-face {
  font-family: "Cont";   /*Can be any text*/
  src: local("Cont"),
    url("./fonts/conthrax.ttf") format("truetype");
}

.container {
  margin: 0 auto;

  .wrapper {
    padding: 0 48px;
    .home {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      h5 {
        margin-top: 260px;
        font-size: 24px;
        font-weight: 600;
        padding-right: 360px;
      }
    }
  }
}

header {
  height: 100px;
  z-index: 300000;
  .inner-header {
    position: fixed;
    height: 100px;
    display: flex;
    width: 94%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index: 100000;
    padding: 20px;
    margin-top: 10px;

    @media screen and (max-width: 1360px) {
      width: 90%;
    }

    @media screen and (max-width: 760px) {
      width: 82%;
      padding: 0px;
    }

    .logo a {
      font-weight: 700;
      font-size: 5rem;
      text-decoration: none;
      color: white;
    }
    .menu {
      button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 0.8rem;
        mix-blend-mode: difference;
        color: white
      }
    }
  }
  .hamburger-menu {
    display: none;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background-image: linear-gradient(35deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      // background-image: linear-gradient(35deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
      height: 100%;
      background-color: black;
      overflow: hidden;
      .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        animation-name: backgroundEffect;
        animation-duration: 30s;
        animation-iteration-count: infinite;
      }
      .wrapper {
        position: relative;
        .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 200px;
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                font-size: 5rem;
                font-weight: 700;
                cursor: pointer;
                height: 155px;
                overflow: hidden;
                position: relative;
                width: 700px;

                @media screen and (max-width: 620px) {
                  font-size: 4rem
                }

                @media screen and (max-width: 500px) {
                  font-size: 3.6rem
                }

                @media screen and (max-width: 460px) {
                  font-size: 3rem
                }

                @media screen and (max-width: 400px) {
                  font-size: 2.7rem
                }
                
                a {
                  position: absolute;
                  color: white;
                  text-decoration: none;
                  font-family: Neue;
                  &:hover {
                    color: white;
                  }
                }
              }
            }
          }
          .info {
            color: #fff;
            width: 300px;
            h3 {
              font-size: 1.2rem;
              margin: 8px auto;
            }
            p {
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }

        }
        .locations {
          position: absolute;
          bottom: -80px;
          color: white;
          margin-top: 16px;
          font-size: 1.4rem;
          font-weight: 600;
          font-family: NeueUltra;
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }

          @media screen and (max-width: 900px) {
              font-size: 1rem;
          } 
          
          @media screen and (max-width: 800px) {
            font-size: 0.9rem;
        } 

        @media screen and (max-width: 690px) {
          font-size: 0.7rem;
        } 

        @media screen and (max-width: 600px) {
          display: none;
        }


          
        }
      }
    }
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}

